@charset "utf-8";

@import "so-simple";

ul {
  list-style-type: square;
}

// Style social icons added to masthead.html
.masthead {
  .social-icons {
    margin-bottom: 0.5em;
  }

  .social-icon {
    display: inline-block;
    padding: 0 0.25em;
  }
}

/** @see https://css-tricks.com/fluid-width-video/ */
video {
  /* override other styles to make responsive */
  width: 100%    !important;
  height: auto   !important;
}
